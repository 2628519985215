import { IMe, IPermissions, ISubscription } from "../models";

import { api } from "./api";
import { sleep } from "@daxrm/utils";

export const getMe = async (): Promise<IMe> => {
  // await sleep(10000);
  const response = await api.get<IMe>("/account/me");
  return response.data;
};

export const getPermissions = async (): Promise<IPermissions> => {
  const response = await api.get<IPermissions>("/account/permissions");
  return response.data;
};

export const getSubscriptions = async (): Promise<ISubscription[]> => {
  const response = await api.get<{ subscriptions: ISubscription[] }>(
    "/account/subscriptions"
  );
  return response.data.subscriptions;
};
